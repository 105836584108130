import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import "./Home.css";

export class Home extends Component {
  componentDidMount() {
    document.title = "Van Thienen";
  }
  public render() {
    return (
      <div className="background">
        <Box py={10}></Box>
      </div>
    );
  }
}
