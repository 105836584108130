import React from "react";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "../home/Home";
import Appbar from "../appbar/Appbar";
import ScrollToTop from "../shared/ScrollToTop";
import "@fontsource/roboto";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/900.css";

const defaultTheme = createMuiTheme({
  palette: {
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    background: {
      paper: "#fafafa",
      default: "#fafafa",
    },
    primary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
  typography: {
    h4: {
      padding: "50px 0 50px 0",
    },
    body1: {
      fontWeight: "lighter",
    },
  },
});

function App() {
  return (
    <Router>
      <ScrollToTop />
      <ThemeProvider theme={defaultTheme}>
        <Appbar />
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

export default App;
